import React, { useContext, useState } from 'react';
import './Addvillage.css';
import Mycontext from '../../Mycontext';
import { writeBatch, doc } from "firebase/firestore";
import { db } from '../../Firebase';
import search from '../../Assets/search.png';
import { useNavigate } from 'react-router-dom';

function Addvillage(){
    const batch = writeBatch(db);
    const navigate= useNavigate();
    const [searchitem,setsearchitem] = useState('');
    const sharedvalue = useContext(Mycontext)
    const [Address,setAddress] = useState({
        village:'',
        mandal:'',
        district:'',
        state:'',
        country:'india'

    })
    const villagelist = Object.keys(sharedvalue.villages);
    villagelist.sort();
    // console.log('here is all hotel villages',sharedvalue.hotels.villages[villagelist[0]][0].data[2])
    async function resetdata(){
        await setAddress({
            village:'',
            mandal:'',
            district:'',
            state:'',
            country:'india'
    
        });
    }
    async function handleaddvillage(){
        try{
            const idx = villagelist.indexOf(Address.village);
            if(idx===-1){
                const sfRef = doc(db, "hotels", "Z2agNfj6NTC6kxcoZSF1");
                const valuevillage = Address.village;
                const tempdata={
                    ...sharedvalue.hotels,
                    villages:{
                        ...sharedvalue.villages,
                        [valuevillage]:[{data:[Address.village,Address.mandal,Address.district,Address.state,Address.country]}]
                    }
                }
                await batch.update(sfRef, tempdata);
                await batch.commit();
                await resetdata();
                alert('Successfully added your Data');

            }else{
                alert('already added the village!!!');
            }
        }catch(e){
            console.log('you got an error while adding a village',e);
            alert('you got an error while adding a village');
        }
    }
    return(
        <>
            <div className='Addvillage-con'>
                <div className='Addvillage-head'>
                    {/* Add village/town head here */}
                    <h1>Add Town/Village</h1>
                    <p>You are required to specify the towns/villages where you wish to enable delivery services.</p>
                </div>
                <div className='Addvillage-form'>
                    {/*add village/town form*/}
                    <div>
                        <label>Village</label>
                        <input type='text' placeholder='Enter Village' value={Address.village} onChange={(e)=>setAddress(prev=>(
                            {
                                ...prev,
                                village:e.target.value
                            }
                        ))}/>
                    </div>
                    <div>
                        <label>Mandal</label>
                        <input type='text' placeholder='Enter Mandal' value={Address.mandal}  onChange={(e)=>setAddress(prev=>(
                            {
                                ...prev,
                                mandal:e.target.value
                            }
                        ))}/>
                    </div>
                    <div>
                        <label>District</label>
                        <input type='text' placeholder='Enter District' value={Address.district} onChange={(e)=>setAddress(prev=>(
                            {
                                ...prev,
                                district:e.target.value
                            }
                        ))}/>
                    </div>
                    <div>
                        <label>State</label>
                        <input type='text'  placeholder='Enter State' value={Address.state} onChange={(e)=>setAddress(prev=>(
                            {
                                ...prev,
                                state:e.target.value
                            }
                        ))}/>
                    </div>
                    <div>
                        <label>Country : India</label>
                    </div>
                    <div>
                        <button className={Address.village!=='' && Address.Mandal!=='' && Address.district!=='' && Address.state!==''?'active-adv-btn':'inactive-adv-btn'} onClick={()=>handleaddvillage()}>Add Village</button>
                    </div>
                </div>
                <div className='Addvillage-list'>
                    {/* all villages display here and you can add edges here */}
                    <div className='Addvillage-head'>
                        <h1>all villages</h1>
                        <p>Here You will Add Hotel towns to  All your villages/Towns</p>
                    </div>
                    <div className='adv-search'>
                        <img src={search} alt="search"/>
                        <input type='text' placeholder='Search Village' onChange={(e)=>setsearchitem(e.target.value)}/>
                    </div>
                    {/* list will display here!!! */}
                    <div className='adv-list-part1'>
                    {
                        villagelist.filter((dtm)=>dtm.includes(searchitem)).map((item,idx)=>(
                            <div key={idx}>
                                <h1>{sharedvalue.hotels.villages[item][0].data[0]}</h1>
                                <p>{sharedvalue.hotels.villages[item][0].data[1]}, {sharedvalue.hotels.villages[item][0].data[2]},
                                 {sharedvalue.hotels.villages[item][0].data[3]}, {sharedvalue.hotels.villages[item][0].data[4]}</p>
                                 <section><button onClick={()=>navigate(`/addvillage/${item}`)}>Attach Towns</button></section>
                            </div>
                        ))
                    }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Addvillage;