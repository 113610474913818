import React, { useContext} from 'react';
import './Navbar.css';
import logo from '../../Assets/logo.png';
import {useNavigate } from 'react-router-dom';
import Mycontext from '../../Mycontext';
import {signOut } from "firebase/auth";
import { auth } from '../../Firebase';


function Navbar(){
    const navigate = useNavigate();
    const sharedvalue = useContext(Mycontext);

    async function handlelogout(){
        try{
            signOut(auth).then(() => {
                alert('you have successfully logged out!!!');
                navigate('/login');
              }).catch((error) => {
                console.log('you got an error while signout',error);
              });
              
        }catch(e){
            alert('you got an error while signout');
        }
    }
    return(
        <>
            <div className='nav-con'>
                <div className='nav-head' >
                    <div>
                        <img src={logo} alt='logo' className='nav-logo' onClick={()=>navigate('/')}/>
                        <h1 onClick={()=>navigate('/')}>ZesstiFoad</h1>
                    </div>

                    {sharedvalue.islogged===true?<button className='nav-logout-btn' onClick={()=>handlelogout()}>Log Out</button>:<></>}
                </div>
                
            </div>
            
        </>
    );
}

export default Navbar;