import React, { useContext } from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Homepage from './Components/Homepage/Homepage';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import Login from './Components/Login/Login';
import Error from './Components/Error/Error';
import Register from './Components/Login/Register';
import Mycontext from './Mycontext';
import Addtown from './Components/Addtown/Addtown';
import Addvillage from './Components/Addvillage/Addvillage';
import Attachtowns from './Components/Attachtowns/Attachtowns';

function App() {
  const sharedvalue=useContext(Mycontext);
  return (
    <BrowserRouter>
    <div>
    <Navbar/>
    <Routes>
      <Route path='/' element={<Homepage/>}/>
      {sharedvalue.islogged?<></>:<Route path='/login' element={<Login/>}/>}
      {sharedvalue.islogged?<></>:<Route path='/register' element={<Register/>}/>}
      {sharedvalue.islogged && sharedvalue.role==='isadmin'?<Route path='/addtown' element={<Addtown/>}/>:<></>}
      {sharedvalue.islogged && sharedvalue.role==='isadmin'?<Route path='/addvillage/:village' element={<Attachtowns/>}/>:<></>}
      {sharedvalue.islogged && sharedvalue.role==='isadmin'?<Route path='/addvillage' element={<Addvillage/>}/>:<></>}

      <Route path='*' element={<Error/>}/>
    </Routes>
    </div>
    </BrowserRouter>
    
  );
}

export default App;
