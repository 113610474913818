import React, { useContext} from 'react';
import './Homepage.css';
import homeimg from '../../Assets/homeimg.png';
import { useNavigate } from 'react-router-dom';
import Mycontext from '../../Mycontext';

function Homepage(){
    const navigate = useNavigate();
    const sharedvalue = useContext(Mycontext);
    return(
        <>
            <div className='Home-con'>
                <div className='home-divs'>
                    <div className='home-divs-st'>
                    <h1>Eat What You Cook</h1>
                    <h1>With Us, Together!</h1>
                    <p>When you eat something that cooked by</p>
                    <p> yourself, the happiness is priceless.</p>
                    {sharedvalue.islogged?<button>Download Zessti App</button>:<button onClick={()=>navigate('/login')}>Login</button>}
                    </div>
                    <div className='home-divs-nd-con'>
                    <div className='home-divs-nd'>
                        <img src={homeimg} alt="home"/>
                    </div>
                    </div>
                </div>
                {sharedvalue.islogged && <div className='home-add-btns'>
                    {sharedvalue.role==='isadmin'?<button onClick={()=>navigate('/addtown')}>Add Towns</button>
                    :sharedvalue.role==='isworker'?<button>worker btn1</button>
                    :<button>client btn1</button>}

                    {sharedvalue.role==='isadmin'?<button onClick={()=>navigate('/addvillage')}>Add Villages</button>
                    :sharedvalue.role==='isworker'?<button>worker btn2</button>
                    :<button>client btn2</button>}
                    
                </div> }
            </div>
        </>
    );
}

export default Homepage;