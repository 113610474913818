
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyDA1jyv5GEjRwtL01iPzg2bmJyNRnbhZ74",
//   authDomain: "zessti-c4a78.firebaseapp.com",
//   projectId: "zessti-c4a78",
//   storageBucket: "zessti-c4a78.appspot.com",
//   messagingSenderId: "961984032069",
//   appId: "1:961984032069:web:f5e6932a46a518fb8fe22c",
//   measurementId: "G-B3EM207YSM"
// };

const firebaseConfig = {
  apiKey: "AIzaSyDsrQJ1niR3SeVUwjmyMN9TvxCE9HYbrYU",
  authDomain: "foodtesting-45d25.firebaseapp.com",
  projectId: "foodtesting-45d25",
  storageBucket: "foodtesting-45d25.appspot.com",
  messagingSenderId: "1070966005868",
  appId: "1:1070966005868:web:0557da47e2ffb26da7f849",
  measurementId: "G-RK5MZLNJZW"
};


export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);