import React, { useEffect, useState } from 'react';
import Mycontext from './Mycontext';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './Firebase';
import { doc, onSnapshot} from "firebase/firestore";
import { db } from './Firebase';

const Myprovider = ({children}) =>{
    const [workerdata,setworkerdata] = useState(null);
    const [hotels,sethotels] = useState({
        towns:'',
        villages:''
    });

    //<-----------------------workers data-------------------------------------->//
    const[odta,setodta] = useState({
        islogged:'',
        uid:null,
        role:null,
        workerdata:null
    })

    //<----------------------- Where We Have Share The Value  To All Components-------------------------------->//
    const sharedvalue ={ //here we are passing the sharedvalue which is used for the child components
        islogged:odta.islogged,
        uid:odta.uid,
        role:odta.role,
        workerdata:workerdata,
        hotels:hotels,
        towns:hotels.towns,
        villages:hotels.villages
    }
    //<--------------------------------------------------------------------------------------------------------->//

    

    useEffect(()=>{
        async function handlereaddoc(){
            try{
                const docRef = doc(db, "hotels", "Z2agNfj6NTC6kxcoZSF1");
                const unsub = onSnapshot(docRef, (doc) => {
                    sethotels(doc.data());
                });
                return ()=>unsub();
            }catch(e){
                console.log('you got an erro while loading data',e);
            }
            
          }
        
       
        
        const subscribe = onAuthStateChanged(auth, async (user) => { //on auth state features are using here
            if (user) {
              const uid = user.uid;
              await handlereaddoc();
              setodta(prev=>({ //seting the shared value here
                ...prev,
                uid:uid,
                islogged:true
            }))

            //<-----------------------------------reading the users personal data------------------------------->//
            const unsub = onSnapshot(doc(db, "workers", `${uid}`), (doc) => {
                const tempdata = doc.data();
                setworkerdata(doc.data());
                setodta(prev=>({
                    ...prev,
                    role:tempdata.role
                }))
            });

            

            return ()=>{
                unsub();
            }

             // calling the users personal reading documents function
            //<------------------------------------------------------------------------------------------------->//

            } else {
             setodta(prev=>( // resetting the previous data after signout!!!
                {
                    ...prev,
                    uid:null,
                    islogged:false,
                    role:null,
                    workerdata:null
                }
              ))
             console.log('please signin !!!');
            }
          });
          
          
            
          return ()=>{
            subscribe();
          }
    },[]);
    return <Mycontext.Provider value={sharedvalue}>
        {children}
    </Mycontext.Provider>
}

export default Myprovider;