import React, { useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from "firebase/auth";
import {auth} from '../../Firebase';
import { doc, setDoc } from "firebase/firestore"; 
import { db } from '../../Firebase';


function Register(){
    const navigate = useNavigate();

    const [userdata,setuserdata] = useState({
        name:'',
        email:'',
        password:'',
        cnfpassword:''
    });

    async function handlesignup(){
        try{
            if(userdata.name!=='' && userdata.email!=='' && userdata.password!=='' && userdata.password===userdata.cnfpassword && userdata.password.length>=6 ){
                await createUserWithEmailAndPassword(auth, userdata.email, userdata.password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    console.log('the user is ',user);
                    setDoc(doc(db, "workers", `${user.uid}`),{
                        name:userdata.name,
                        email:userdata.email,
                        role:'isclient',
                        profile:{}
                    });
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log('here is your error',errorCode,'and',errorMessage);
                });
                await navigate('/');
            }else if(userdata.password!==userdata.cnfpassword){
                alert('your password and confirm password must be same');
            }else if(userdata.password<6){
                alert('your password must be greater than or equal to 6 characters');
            }else{
                alert('fill the form correctly');
            }
        }catch(e){
        alert('you got an error in signup');
    }
        
    }
    return(
        <>
            <div className='login-con'>
                <div className='login-form'>
                <div>
                    <label>Name</label>
                    <input type="text" placeholder='Enter Your Full Name' onChange={(e)=>setuserdata(prev=>({
                            ...prev,
                            name:e.target.value
                        }))}/>
                </div>
                <div>
                    <label>Email</label>
                    <input type="email" placeholder='Please Enter Your Email' onChange={(e)=>setuserdata(prev=>({
                            ...prev,
                            email:e.target.value
                        }))}/>
                </div>
                <div>
                    <label>Password</label>
                    <input type="password" placeholder='Enter Password' onChange={(e)=>setuserdata(prev=>({
                            ...prev,
                            password:e.target.value
                        }))}/>
                </div>
                <div>
                    <label>Confirm Password</label>
                    <input type="password" placeholder='Enter Password' onChange={(e)=>setuserdata(prev=>({
                            ...prev,
                            cnfpassword:e.target.value
                        }))}/>
                </div>
                <div>
                    <button onClick={()=>handlesignup()}>Sign Up</button>
                </div>
                <p>Already You have an account? <span onClick={()=>navigate('/login')}>SignIn</span></p>
                </div>
            </div>
        </>
    );
}

export default Register;