import React, { useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import {signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../Firebase';

function Login(){
    const navigate = useNavigate();
    const [userdata,setuserdata] = useState({
        email:'',
        password:''
    });

    async function handlsignin(){
        if(userdata.email!=='' && userdata.password!==''){
            signInWithEmailAndPassword(auth, userdata.email, userdata.password)
                .then((userCredential) => {
                    
                    const user = userCredential.user;
                    alert("you successfully logged in",user);
                    navigate('/');
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log("you got an error",errorCode," and ", errorMessage);
                    alert("you8 goty an error while login");
                });
        }else{
            alert('please fill the form correctly');
        }
    }
    return(
        <>
            <div className='login-con'>
                <div className='login-form'>
                <div>
                    <label>Email</label>
                    <input type="email" placeholder='Please Enter Your Email' onChange={(e)=>setuserdata(prev=>({
                        ...prev,
                        email:e.target.value
                    }))}/>
                </div>
                <div>
                    <label>Password</label>
                    <input type="password" placeholder='Enter Password' onChange={(e)=>setuserdata(prev=>({
                        ...prev,
                        password:e.target.value
                    }))}/>
                </div>
                <div>
                    <button onClick={()=>handlsignin()}>Login</button>
                </div>
                <p>if you didn't have an account? <span onClick={()=>navigate('/register')}>Click Here</span></p>
                </div>
            </div>
        </>
    );
}

export default Login;