import React, { useContext, useState } from 'react';
import './Addtown.css';
import { writeBatch, doc } from "firebase/firestore"; 
import {db} from '../../Firebase';
import Mycontext from '../../Mycontext';
function Addtown(){
    const batch = writeBatch(db);
    const sharedvalue = useContext(Mycontext);
    const [towndata,settowndata] = useState({
        town:'',
        country:'india',
        state:'',
        district:'',
        available:true
    })
    const townslist = Object.keys(sharedvalue.towns);
    townslist.sort();

    async function handleaddtown(){
        try{
            if(towndata.town!==''){
                 // Update the population of 'SF'
                 const index = townslist.indexOf(towndata.town);
                 if(index===-1){
                        const sfRef = doc(db, "hotels", "Z2agNfj6NTC6kxcoZSF1");
                        const valuetown = towndata.town;
                        const tempdata ={
                            ...sharedvalue.hotels,
                            towns:{
                                ...sharedvalue.towns,
                                [valuetown]:{}
                            }
                        }
                        await batch.update(sfRef, tempdata);
                        await batch.commit();
                        settowndata({
                            town:'',
                            country:'india',
                            state:'',
                            district:'',
                            available:true
                        });
                        console.log('town added successfully!!!!');
                 }else{
                    alert('the town already exists!! in our App');
                 }
            
            }else{
                alert('please fill the form correctly!!');
            }
           
        }catch(e){
            console.log('you got an error while adding the town',e);
        }
    }

    return(
        <>
        <div className='addtown-con'>
            <div className='addtown-head'>
                <h1>add hotels town</h1>
                <p>Please provide the names of the towns where the hotels are placed.</p>
            </div>
            <div className='addtown-form'>
                <div>
                    <label>town name:</label>
                    <input value={towndata.town} onChange={(e)=>settowndata(prev=>({
                        ...prev,
                        town:e.target.value
                    }))} placeholder='enter your town name' type='text' className='addtown-input-form'/>
                </div>
                <div className='addtown-second-div'>
                <button onClick={()=>handleaddtown()}>add town</button>
                </div>
                
            </div>
            <div className='all-towns-will-displayhere'>
                <h1>All Towns Will Display Here</h1>
            </div>
            <div className='addtowns-all-towns'>
            {townslist.map((item,idx)=>(
                <div key={idx}>{item}</div>
            ))}
            </div>

        </div>
        </>
    );
}

export default Addtown;