import React, { useContext, useState } from 'react';
import './Attachtowns.css';
import { useParams } from 'react-router-dom';
import Mycontext from '../../Mycontext';
import { v4 as uuidv4 } from 'uuid';
import { writeBatch, doc } from "firebase/firestore"; 
import { db } from '../../Firebase';

function Attachtowns(){
    const batch = writeBatch(db)
    const sharedvalue=useContext(Mycontext)
    const {village}=useParams();
    
    const [attachet,setattahet]=useState({
        id:'',
        town:'',
        distance:'',
        time:'',
        price:''
    });

    const [edititem,setedititem]=useState({
        town:'',
        distance:'',
        time:'',
        price:'',
        id:''
    })
    

    function toogle(){
        var blur=document.getElementById('blur');
        var popupone=document.getElementById('popup1');
        blur.classList.toggle('active');
        popupone.classList.toggle('active');
        setattahet({
            id:'',
            town:'',
            distance:'',
            time:'',
            price:''
        });
    }

    function edittoggle(item){
        var secondpop=document.getElementById('popup2');
        secondpop.classList.toggle('active');
        var blur=document.getElementById('blur');
        blur.classList.toggle('active');
        setedititem({
            town:item.town,
            distance:item.distance,
            time:item.time,
            price:item.price,
            id:item.id
        })
    }
    
    function closeedittoggle(){
        var secondpop=document.getElementById('popup2');
        secondpop.classList.toggle('active');
        var blur=document.getElementById('blur');
        blur.classList.toggle('active');
    }

    async function handleedititem(){
        try{
            if(edititem.distance!=='' && edititem.time!=='' && edititem.town!=='' && edititem.price!==''){
                const sfRef = doc(db, "hotels", "Z2agNfj6NTC6kxcoZSF1");
                const temp_arr=sharedvalue.villages[village].filter((item)=>item.id!==edititem.id && item.town!==edititem.town);
                const tempdata={
                    ...sharedvalue.hotels,
                    villages:{
                        ...sharedvalue.villages,
                        [village]:[...temp_arr,edititem]
                    }
                }
                await batch.update(sfRef, tempdata);
                await batch.commit();
                alert('successfully added your data!!');
                await closeedittoggle();
            }else{
                alert('please fill the form correctly!!')
            }
        }catch(e){
            alert('you got an error while editing the towns');
            console.error('you got an error when editing the twons',e);
        }
    }

    async function handledeletetown(){
        try{
            if(edititem.distance!=='' && edititem.time!=='' && edititem.town!=='' && edititem.price!==''){
                const sfRef = doc(db, "hotels", "Z2agNfj6NTC6kxcoZSF1");
                const temp_arr=sharedvalue.villages[village].filter((item)=>item.id!==edititem.id);
                const tempdata={
                    ...sharedvalue.hotels,
                    villages:{
                        ...sharedvalue.villages,
                        [village]:temp_arr
                    }
                }
                await batch.update(sfRef, tempdata);
                await batch.commit();
                alert('successfully added your data!!');
                await closeedittoggle();
            }else{
                alert('please fill the form correctly!!')
            }
        }catch(e){
            alert('you got an error while deleting');
        }
    }
    async function handleattah(){
        try{
            if(attachet.distance!=='' && attachet.time!=='' && attachet.town!=='' && attachet.price!==''){
                const sfRef = doc(db, "hotels", "Z2agNfj6NTC6kxcoZSF1");
                const temp_attch=sharedvalue.villages[village].filter((item)=>item.town!==attachet.town);
                const tempdata={
                    ...sharedvalue.hotels,
                    villages:{
                        ...sharedvalue.villages,
                        [village]:[...temp_attch,{
                            id:uuidv4(),
                            town:attachet.town,
                            distance:attachet.distance,
                            time:attachet.time,
                            price:attachet.price
                        }]
                    }
                }
                await batch.update(sfRef, tempdata);
                await batch.commit();
                alert('successfully added your data!!');
                await toogle();
            }else{
                alert('please fill the form correctly!!')
            }
        }catch(e){
            alert('you got an error while attahing the town');
            console.log('you got an error when attahing town',e);
        }
        
    }

    const townslist = Object.keys(sharedvalue.towns);
    townslist.sort();
    
    return(
    <>
        <div className='attacht-con' id='blur'>
            <div className='attacht-header'>
                <h1>Attach Hotels to Villages</h1>
                <p>"Please attach all hotels to their respective towns, and you will be able to view those hotels near the associated towns/villages."</p>
            </div>
            <div>
                <div className='attacht-vhead'>
                    <h1>{village}</h1>
                    <p>Please associate the hotels with the village of {village}.</p>
                </div>
                {/* display all added towns here thats it!!! */}
                <div className='attachtowns-all-towns'>
                    {
                        sharedvalue.villages[village].map((item,idx)=>{
                            if(idx!==0){
                                return(
                                    <div className='attachtowns-each-town' key={item.id}>
                                        <div className='attachtowns-each-town1'>
                                            <div>
                                                <p><span>Town:</span> {item.town}</p>
                                                <p><span>Distance:</span> {item.distance} KM</p>
                                            </div>
                                            <div>
                                                <button onClick={()=>edittoggle(item)}>Edit</button> 
                                            </div>
                                        </div>
                                        <div className='attachtowns-each-town2'>
                                            <p><span>Price:</span> {item.price}/-</p>
                                            <p><span>Time:</span> {item.price} min</p>
                                        </div>
                                    </div>
                                )
                            }else{
                                return <div key={idx}></div>
                            }
                        })
                    }
                </div>
                <div className='attacht-tbtn'>
                    <button onClick={()=>toogle()}>Attach Town</button>
                </div>
            </div>

        </div>

        {/* this is my first popup for attaching the towns */}
        <div className='attachtowns-popup1' id='popup1'>
            <div className='attach-popups1'>
                <section className='attacht-vhead'>
                    <h1>{village}</h1>
                </section>
                <div>
                    <label>Town:</label>
                    <select value={attachet.town} onChange={(e)=>setattahet(prev=>(
                        {
                            ...prev,
                            town:e.target.value
                        }
                    ))}>
                        <option value=''>Select Towns</option>
                        {townslist.map((item,idx)=>(
                            <option key={idx} value={`${item}`}>{item}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Distance(KM):</label>
                    <input type='number' placeholder='in KM' value={attachet.distance} onChange={(e)=>setattahet(prev=>(
                        {
                            ...prev,
                            distance:e.target.value
                        }
                    ))}/>
                </div>
                <div>
                    <label>Time(minutes):</label>
                    <input type='number' placeholder='in min' value={attachet.time} onChange={(e)=>setattahet(prev=>(
                        {
                            ...prev,
                            time:e.target.value
                        }
                    ))}/>
                </div>
                <div>
                    <label>Price(₹):</label>
                    <input type='number' placeholder='in Rupee' value={attachet.price} onChange={(e)=>setattahet(prev=>(
                        {
                            ...prev,
                            price:e.target.value
                        }
                    ))}/>
                </div>
                <section className='attach-popup-btns'>
                    <button onClick={()=>toogle()}>cancle</button>
                    <button onClick={()=>handleattah()}>attach</button>
                </section>
            </div>
        </div>

        {/* here we have to start the second popup for editing purpose */}
        <div className='secondpopup' id='popup2'>
        <div className='attach-popups1'>
                <section className='attacht-vhead'>
                    <h1>{village}</h1>
                </section>
                <div>
                    <label>Town:</label>
                    <select value={edititem.town} onChange={(e)=>setedititem(prev=>(
                        {
                            ...prev,
                            town:e.target.value
                        }
                    ))}>
                        <option value=''>Select Towns</option>
                        {townslist.map((item,idx)=>(
                            <option key={idx} value={`${item}`}>{item}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Distance(KM):</label>
                    <input type='number' placeholder='in KM' value={edititem.distance} onChange={(e)=>setedititem(prev=>(
                        {
                            ...prev,
                            distance:e.target.value
                        }
                    ))}/>
                </div>
                <div>
                    <label>Time(minutes):</label>
                    <input type='number' placeholder='in min' value={edititem.time} onChange={(e)=>setedititem(prev=>(
                        {
                            ...prev,
                            time:e.target.value
                        }
                    ))}/>
                </div>
                <div>
                    <label>Price(₹):</label>
                    <input type='number' placeholder='in Rupee' value={edititem.price} onChange={(e)=>setedititem(prev=>(
                        {
                            ...prev,
                            price:e.target.value
                        }
                    ))}/>
                </div>
                <section className='attach-popup-btns-cancel'>
                        <button onClick={()=>closeedittoggle()}>cancle</button>
                    <div className='attach-popup-btns-delete-save'>
                        <button onClick={()=>handledeletetown()}>Delete</button>
                        <button onClick={()=>handleedititem()}>save</button>
                    </div>
                    
                </section>
            </div>
        </div>
    </>
    );
}

export default Attachtowns;